<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.8rem;">文件标题：</span>
					<el-input v-model="searchinput" placeholder="请输入" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<!-- <el-button type="success" @click="addnew">新建公文处理单</el-button> -->
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="180" @getdata="gettable" @changswitch="changswitch">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showdetail(scope.row)" type="primary" size="mini">详情</el-button>
							<el-button v-if="scope.row.type==1" @click="postdata(scope.row)" type="success" size="mini">拟办</el-button>
							<el-button v-if="scope.row.type==2" @click="pishi(scope.row)" type="success" size="mini">批示</el-button>
							<el-button v-if="scope.row.type==3" @click="sjb(scope.row)" type="success" size="mini">交办</el-button>
							<el-button v-if="scope.row.type==4||(scope.row.type==5&&scope.row.bl_state==1)" @click="ksbanli(scope.row)" type="success" size="mini">开始办理</el-button>
							<el-button v-if="scope.row.type==5&&scope.row.bl_state==2" @click="sbanli(scope.row)" type="success" size="mini">完成办理</el-button>
							<el-button v-if="scope.row.type==6" @click="gdang(scope.row)" type="success" size="mini">归档</el-button>
							<!-- <el-button v-if="scope.row.type==4" @click="sldps(scope.row)" type="success" size="mini">批示</el-button>
							 -->
							<!-- <el-button v-if="scope.row.type==3" @click="sxzld(scope.row)" type="success" size="mini">选择领导</el-button>							
							 -->
						</template>
					</MyTable>
				</div>
			</div>
		</div>
		
		<!--确认拟办弹出-->
		<el-dialog title="确认拟办" :visible.sync="showtc" width="40%" :before-close="setsheng">
			<div>
				<el-form ref="ruleForm" class="demo-ruleForm" label-position="left">
					  <el-form-item label="拟办意见" required>
							<el-input
							  type="textarea"
							  :rows="4"
							  placeholder="请输入拟办意见"
							  v-model="sheng.nbyj">
							</el-input>
					  </el-form-item>
					  <el-form-item label="拟办类型" required label-width="100px">					  		
							<el-radio-group v-model="sheng.nbtype">
							    <el-radio :label="1">需要批示</el-radio>
							    <el-radio :label="2">空节点</el-radio>
							</el-radio-group>
					  </el-form-item>
					  <el-form-item label="批示类型" required label-width="100px" v-if="sheng.nbtype==1">
					  		<el-radio-group v-model="sheng.pstype">
					  		    <el-radio :label="1">同时执行</el-radio>
					  		    <el-radio :label="2">串发执行</el-radio>
					  		</el-radio-group>
					  </el-form-item>
					  <el-form-item label="批示人" required label-width="100px" v-if="sheng.nbtype==1">
					  		<div style="display: flex;align-items: center;">
					  			<el-button type="primary" size="small" @click="select(1)">选择</el-button>								
					  		</div>
					  		<div>
					  			<span style="color: #999;">已选人员：</span>{{user_name}}
					  		</div>
					  </el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setsheng">取 消</el-button>
				<el-button type="success" @click="queren">通 过</el-button>
			</span>
		</el-dialog>
		
		
		
		<!--批示弹出-->
		<el-dialog title="确认批示" :visible.sync="showps" width="40%" :before-close="setshengps">
			<div>
				<el-form ref="ruleForm" class="demo-ruleForm">
					  <el-form-item label="批示意见">
							<el-input
							  type="textarea"
							  :rows="4"
							  placeholder="请输入批示意见"
							  v-model="shengps.jdps">
							</el-input>
					  </el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setshengps">取 消</el-button>
				<el-button type="success" @click="querenps">通 过</el-button>
			</span>
		</el-dialog>
		
		
		<!--选择领导-->
		<el-dialog title="选择领导" :visible.sync="showxzld" width="40%" :before-close="setsheng_xzld">
			<div>
				<el-form ref="ruleForm" class="demo-ruleForm">
					  <el-form-item label="选择领导" required>
					  		<div style="display: flex;align-items: center;">
					  			<el-button type="primary" size="small" @click="select(5)">选择</el-button>								
					  		</div>
					  		<div>
					  			<span style="color: #999;">已选人员：</span>{{user_name_xzld}}
					  		</div>
					  </el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setsheng_xzld">取 消</el-button>
				<el-button type="success" @click="queren_xzld">通 过</el-button>
			</span>
		</el-dialog>
		
		
		<!--领导批示-->
		<el-dialog title="确认批示" :visible.sync="showldps" width="40%" :before-close="setshengldps">
			<div>
				<el-form ref="ruleForm" class="demo-ruleForm">
					  <el-form-item label="批示意见" required>
							<el-input
							  type="textarea"
							  :rows="4"
							  placeholder="请输入批示意见"
							  v-model="shengldps.wps">
							</el-input>
					  </el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setshengldps">取 消</el-button>
				<el-button type="success" @click="querenldps">通 过</el-button>
			</span>
		</el-dialog>
		
		
		<!--交办-->
		<el-dialog title="确认交办" :visible.sync="showjb" width="40%" :before-close="setshengjb">
			<div>
				<el-form ref="ruleForm" class="demo-ruleForm">
					  <el-form-item label="选择部门/人员" required>
					  		<div style="display: flex;align-items: center;">
					  			<el-button type="primary" size="small" @click="select(6)">选择</el-button>								
					  		</div>
					  		<div>
					  			<span style="color: #999;">已选人员：</span>{{user_name_jb}}
					  		</div>
					  </el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setshengjb">取 消</el-button>
				<el-button type="success" @click="querenjb">交 办</el-button>
			</span>
		</el-dialog>
		
		
		<!--办理-->
		<el-dialog title="完成办理" :visible.sync="showbl" width="40%" :before-close="setshengbl">
			<div>
				<el-form ref="ruleForm" class="demo-ruleForm">
					  <el-form-item label="办理结果" required>
					  		<el-input
					  		  type="textarea"
					  		  :rows="4"
					  		  placeholder="请输入办理结果"
					  		  v-model="shengbl.nljg">
					  		</el-input>		
					  </el-form-item>
					  <el-form-item label="上传附件" style="display: flex;align-items: center;">
					  	<el-upload
					  	  class="upload-demo"
					  	  :action="baseurl+'/api/login/upload'"
					  	  :on-success="handleAvatarSuccess"
					  	  :on-remove="handleChange"
					  	  :file-list="fileList">
					  	  <el-button size="small" type="primary">点击上传</el-button>
					  	</el-upload>
					  </el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setshengbl">取 消</el-button>
				<el-button type="success" @click="querenbl">完成办理</el-button>
			</span>
		</el-dialog>
		
		
		<detail ref="mydetail"></detail>
		<selectuser ref="myselectuser" @selectedry="selectedry"></selectuser>
	</div>
</template>

<script>
	import detail from '../components/detail.vue'
	import selectuser from '@/components/select_user.vue'
	export default {
		components:{ detail,selectuser },
		data() {
			return {
				searchinput: '',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},
					{
						prop: 'name',
						name: '文件标题',
						width: ''
					},
					{
						prop: 'lydw',
						name: '来文单位',
						width: '250'
					},
					{
						prop: 'jjcd',
						name: '紧急程度',
						width: '120',
						type:'jjcd'
					},
					{
						prop: 'type',
						name: '当前环节',
						width: '110',
						type: 'huanjie'
					},
					{
						prop: 'type',
						name: '当前处理人',
						width: '110',
						type:'clr'
					},
					{
						prop: 'addtime',
						name: '创建时间',
						width: ''
					}
				],
				tableData: [],
				showtc:false,
				sheng:{
					id:'',
					nbyj:'',
					psuser_id:'',
					jbuser_id:'',
					wpsuser_id:'',
					nbtype:1,//1需要批示，2空节点
					pstype:1,//1同时，2串发
					csr:''
				},
				showps:false,//批示
				shengps:{//批示
					id:'',
					jdps:''
				},
				showxzld:false,//选择领导
				sheng_xzld:{//选择领导
					id:'',
					wpsuser_id:''
				},
				user_name_xzld:'',//选择领导
				user_id_xzld:'',//选择领导
				
				showldps:false,//领导批示
				shengldps:{//领导批示
					id:'',
					wps:''
				},
				
				
				showjb:false,//交办
				shengjb:{//交办
					id:'',
					jbuser_id:''
				},
				user_name_jb:'',//交办
				user_id_jb:'',//交办
				
				baseurl:"",
				showbl:false,//办理
				shengbl:{//办理
					id:'',
					nljg:'',
					blfj:''
				},
				fileList:[],//办理
				
				selet_type:0,
				user_name:'',
				user_id:'',
				user_name2:'',
				user_id2:'',
				user_name3:'',
				user_id3:'',
				user_name4:'',
				user_id4:'',
			}
		},
		mounted() {
			this.baseurl = this.$URL
			this.gettable()
		},
		methods: {
			ksbanli(row){
				this.$confirm('您确定开始办理吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: '/api/matter/jsbanli',
						params: {
							id:row.id
						}
					}).then((res) => {
						this.$message.success('操作成功')
						this.gettable()
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				})
			},
			
			//归档-------------------------------
			gdang(row){
				this.$confirm('您确定归档吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: '/api/matter/cundang',
						params: {
							id:row.id
						}
					}).then((res) => {
						this.$message.success('操作成功')
						this.gettable()
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				})
			},
			//归档end------------------------------
			
			
			//办理--------------------------
			sbanli(row) {
				this.showbl = true
				this.shengbl.id = row.id
			},
			handleAvatarSuccess(res, file) {
				let obj ={
					name:res.file_name,
					url:res.fullurl,
					id:res.id
				}
				this.fileList.push(obj)
			},
			handleChange(file, fileList) {
				this.fileList = fileList			    
			},
			querenbl(){
				if(!this.shengbl.nljg){
					this.$message.warning('请输入办理结果')
					return
				}
				if(this.fileList.length>0){
					this.shengbl.blfj = this.fileList.map((item)=>{return item.id}).join(',')
				}
				this.$post({
					url: '/api/matter/tjwc',
					params: this.shengbl
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
					this.setshengbl()
				})
			},
			setshengbl(){
				this.shengbl={
					id:'',
					nljg:'',
					blfj:''
				}
				this.fileList=[]
				this.showbl = false
			},
			//办理end-----------------------
			
			
			
			
			//交办--------------------
			sjb(row) {
				this.shengjb.jbuser_id = row.jbuser_id
				this.user_name_jb = row.jbuser_name
				this.user_id_jb = row.jbuser_id
				this.showjb = true
				this.shengjb.id = row.id
			},			
			querenjb(){
				if(!this.shengjb.jbuser_id){
					this.$message.warning('请选择部门或人员')
					return
				}
				this.$post({
					url: '/api/matter/jiaoban',
					params: this.shengjb
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
					this.setshengjb()
				})
			},
			setshengjb(){
				this.shengjb={
					id:'',
					jbuser_id:''
				}
				this.showjb = false
			},
			//交办end---------------------------
			
			
			
			//领导批示--------------------------------
			sldps(row) {
				this.showldps = true
				this.shengldps.id = row.id
			},
			querenldps(){
				if(!this.shengldps.wps){
					this.$message.warning('请输入批示意见')
					return
				}
				this.$post({
					url: '/api/matter/wldpishi',
					params: this.shengldps
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
					this.setshengldps()
				})
			},
			setshengldps(){
				this.shengldps={
					id:'',
					wps:''
				}
				this.showldps = false
			},
			//领导批示结束-----------------------------
			
			
			//选择领导-----------------------------------
			sxzld(row){
				this.user_name_xzld = row.wpsuser_name
				this.user_id_xzld = row.wpsuser_id
				this.sheng_xzld.wpsuser_id = row.wpsuser_id
				this.showxzld = true
				this.sheng_xzld.id = row.id
			},
			queren_xzld(){
				if(!this.sheng_xzld.wpsuser_id){
					this.$message.warning('请选择领导')
					return
				}
				this.$post({
					url: '/api/matter/wjiaoban',
					params: this.sheng_xzld
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
					this.setsheng_xzld()
				})
			},
			setsheng_xzld(){
				this.sheng_xzld={
					id:'',
					wpsuser_id:''
				}
				this.showxzld = false
			},
			//选择领导结束--------------------------------
			
			
			//批示--------------------------
			pishi(row) {
				this.showps = true
				this.shengps.id = row.id
			},
			setshengps(){
				this.shengps={
					id:'',
					jdps:''
				}
				this.showps = false
			},
			querenps(){				
				this.$post({
					url: '/api/matter/pishi',
					params: this.shengps
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
					this.setshengps()
				})
			},
			//批示结束-------------------------
			queren(){
				if(!this.sheng.nbyj){
					this.$message.warning('请输入拟办意见')
					return
				}
				if(this.sheng.nbtype==1&&!this.sheng.psuser_id){
					this.$message.warning('请选择批示人')
					return
				}
				this.$post({
					url: '/api/matter/niban',
					params: this.sheng
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
					this.setsheng()
				})
			},
			setsheng(){
				this.sheng={
					id:'',
					nbyj:'',
					psuser_id:'',
					nbtype:1,
					pstype:1,
					csr:''
				}
				this.showtc = false
			},
			select(index){
				this.selet_type = index
				let cxidlist = []
				let cxnamelist = []
				if(index==1){//选择批示人
					cxidlist = this.user_id?this.user_id.split(','):[]
					cxnamelist = this.user_name?this.user_name.split(','):[]
					this.$refs.myselectuser.isdanxuan = false
				}
				if(index==2){//选择抄送人
					this.$refs.myselectuser.isdanxuan = false
					cxidlist = this.user_id2?this.user_id2.split(','):[]
					cxnamelist = this.user_name2?this.user_name2.split(','):[]
				}	
				if(index==3){//选择交办人
					this.$refs.myselectuser.isdanxuan = false
					cxidlist = this.user_id3?this.user_id3.split(','):[]
					cxnamelist = this.user_name3?this.user_name3.split(','):[]
				}
				if(index==4){//领导批示人
					this.$refs.myselectuser.isdanxuan = false
					cxidlist = this.user_id4?this.user_id4.split(','):[]
					cxnamelist = this.user_name4?this.user_name4.split(','):[]
				}	
				
				if(index==5){//选择领导
					this.$refs.myselectuser.isdanxuan = false
					cxidlist = this.user_id_xzld?this.user_id_xzld.split(','):[]
					cxnamelist = this.user_name_xzld?this.user_name_xzld.split(','):[]
				}	
				
				if(index==6){//交办
					this.$refs.myselectuser.isdanxuan = false
					cxidlist = this.user_id_jb?this.user_id_jb.split(','):[]
					cxnamelist = this.user_name_jb?this.user_name_jb.split(','):[]
				}
				let list = []
				if(cxidlist.length>0){
					cxidlist.forEach((item,index)=>{
						let obj={
							name:cxnamelist[index],
							id:Number(item)
						}
						list.push(obj)
					})
				}	
				this.$refs.myselectuser.selectuser = list.length>0?list:[]
				this.$refs.myselectuser.dialogVisible = true
			},
			selectedry(e){
				if(this.selet_type==1){
					this.user_id = e.map((item)=>{ return item.id })
					this.user_id = this.user_id.join(',')
					this.user_name = e.map((item)=>{ return item.name })
					this.user_name = this.user_name.join(',')
					
					this.sheng.psuser_id = this.user_id
				}
				if(this.selet_type==2){
					this.user_id2 = e.map((item)=>{ return item.id })
					this.user_id2 = this.user_id2.join(',')
					this.user_name2 = e.map((item)=>{ return item.name })
					this.user_name2 = this.user_name2.join(',')
					
					this.sheng.csr = this.user_id2
				}
				if(this.selet_type==3){
					this.user_id3 = e.map((item)=>{ return item.id })
					this.user_id3 = this.user_id3.join(',')
					this.user_name3 = e.map((item)=>{ return item.name })
					this.user_name3 = this.user_name3.join(',')
					
					this.sheng.jbuser_id = this.user_id3
				}
				if(this.selet_type==4){
					this.user_id4 = e.map((item)=>{ return item.id })
					this.user_id4 = this.user_id4.join(',')
					this.user_name4 = e.map((item)=>{ return item.name })
					this.user_name4 = this.user_name4.join(',')
					
					this.sheng.wpsuser_id = this.user_id4
				}
				
				if(this.selet_type==5){//选择领导
					this.user_id_xzld = e.map((item)=>{ return item.id })
					this.user_id_xzld = this.user_id_xzld.join(',')
					this.user_name_xzld = e.map((item)=>{ return item.name })
					this.user_name_xzld = this.user_name_xzld.join(',')
					
					this.sheng_xzld.wpsuser_id = this.user_id_xzld
				}
				
				if(this.selet_type==6){//交办
					this.user_id_jb = e.map((item)=>{ return item.id })
					this.user_id_jb = this.user_id_jb.join(',')
					this.user_name_jb = e.map((item)=>{ return item.name })
					this.user_name_jb = this.user_name_jb.join(',')
					
					this.shengjb.jbuser_id = this.user_id_jb
				}
			},
			showdetail(row){
				this.$post({
					url: '/api/matter/details',
					params: {
						id:row.id
					}
				}).then((res) => {
					this.$refs.mydetail.info = res
					this.$refs.mydetail.dialogVisible = true
				})
			},
			postdata(row) {
				this.showtc = true
				this.sheng.nbyj = row.nbyj
				this.sheng.id = row.id
			},
			showeidt(row) {
				this.$router.push('/system/get_file/edit?id=' + row.id)
			},
			changswitch(row) {
				this.$post({
					url: '/api/room/edit',
					params: row
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
				})
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/matter/appindex',
					params: {
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize,
						type: '1,2,3,4,5,6'
					}
				}).then((res) => {
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			addnew() {
				this.$router.push('/system/get_file/add')
			},
			showdate(row) {
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import '../public.scss';
</style>